<div class="container avatar-svg-container--sized">
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
    <g stroke="#0000ff" stroke-width="5" fill="#0000ff" fill-rule="nonzero">
      <path
        d="M371 11.5c-36.6 5-68.5 20.2-93.1 44.5-15.7 15.5-38.3 47-55.7 77.5-45 79.1-174.2 326.7-196.6 376.9-11.7 26.2-15.1 41.3-15.1 66.6.1 24 4.4 42.9 14.2 62.5 22 43.8 61.9 69.8 117.3 76.5 24 2.9 50.9-.4 77-9.5 24.8-8.6 46.6-21.7 81-48.6 10.7-8.3 22.7-17.3 26.5-19.9 14.4-9.7 30.6-16.5 47.3-19.6 12.8-2.4 35.4-1.5 47.7 1.9 22.9 6.4 34.5 13.4 71 42.7 27.1 21.7 49.9 34.9 74 42.9 48.2 16 102 12.4 140.9-9.3 57.1-31.9 82.1-103.3 60.9-173.6-7.6-25.4-7-24.1-76.6-154-66.5-124.2-110.2-204.5-127.3-233.7-17.9-30.6-40.3-62.8-53.4-76.6-34-36.1-88-54.3-140-47.2zM419.2 56c30 6.2 51.4 20 71.2 46.3 16.4 21.8 31.8 46.8 58.9 95.8C596.4 283.5 710.7 496.6 717 511c15.9 36 18.8 66.2 9.4 96-12.2 38.9-44.1 62.4-87.8 64.9-36.9 2.1-67-6.2-97.8-27-3.1-2.1-14.5-10.9-25.3-19.5-10.9-8.6-24.7-19-30.6-23-43.1-29.2-94.1-36.7-141.5-20.9-9 3-28.4 12.1-35.9 16.8-7 4.4-21.2 14.9-40 29.5-19.6 15.3-30.8 22.8-44.5 29.7-37.7 18.9-74.1 21.1-111.5 6.6-37.8-14.6-61.4-55.4-56.7-97.9 2.3-20.9 5.1-27.8 37.8-93.2 51.1-102.2 157.3-301.9 178.5-335.6 11.3-18.1 27.1-39.6 35.8-48.8 26.9-28.3 70.8-41 112.3-32.6z"
      >
      </path>
      <path
        d="M372.6 308.1c-14.5 2-31.3 7.3-45.1 14.2-8.8 4.4-20.7 11.7-24.3 15.1-8.4 7.5-8.7 21.8-.8 30.4 5.9 6.5 18.7 8.8 25.7 4.5 1.9-1.1 7.2-4.4 11.8-7.2 17.3-10.5 31.7-14.4 53.1-14.4s35.8 3.9 53.1 14.4c4.6 2.8 9.9 6.1 11.8 7.2 7 4.3 19.8 2 25.7-4.5 7.9-8.6 7.6-22.9-.8-30.4-6.7-6.2-26.6-17.1-39.8-21.9-15.4-5.6-27.1-7.6-46-8-9.6-.2-20.6 0-24.4.6z"
      />
    </g>
  </svg>
</div>
  