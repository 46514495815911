import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: 'app/main/dashboard', pathMatch: 'full' },
                        ],
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then((m) => m.MainModule), //Lazy load main module
                        data: { preload: true },
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: 'biz-dev',
                        loadChildren: () => import('./biz-dev-1.0/biz-dev-1.0.module').then(m => m.BizDev1dot0Module)
                    },
                    {
                        path: 'growth',
                        loadChildren: () => import('./growth-1.0/growth-1.0.module').then(m => m.Growth1Dot0Module), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'insights',
                        loadChildren: () => import('@app/insights-1.0/insights-1.0.module').then(m => m.Insights1Dot0Module), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'product',
                        loadChildren: () => import('@app/product-1.0/product-1.0.module').then(m => m.Product1Dot0Module), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'finance',
                        loadChildren: () => import('@app/finance-1.0/finance-1.0.module').then(m => m.Finance1Dot0Module), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'crm',
                        loadChildren: () => import('@app/crm-1.0/crm.module').then(m => m.Crm1Dot0Module), //Lazy load main module
                    },
                    {
                        path: '**',
                        redirectTo: 'notifications',
                    },
                ],
            },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
