import { Component, OnDestroy, OnInit } from '@angular/core';
import { AmpGlobalSpinnerService } from '@app/shared/services/amp-global-spinner.service'//'./shared/amp-global-spinner.service';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@node_modules/@angular/router';
import { Subscription } from '@node_modules/rxjs/dist/types';


@Component({
    selector: 'app-root',
    template: `
        <amp-new-version></amp-new-version>
        <router-outlet></router-outlet>
        <ng-container *ngIf="ampSpinnerService.customIsActive$ | async">
            <amp-global-spinner></amp-global-spinner>
        </ng-container>
        <ng-container *ngIf="ampSpinnerService.normalIsActive$ | async">
            <amp-spinner [global]="true" [text]="ampSpinnerService.normalTextSpinner$ | async| localize"></amp-spinner>
        </ng-container>
    `
})
export class RootComponent implements OnInit, OnDestroy {
    routerSub: Subscription;

    constructor(public ampSpinnerService: AmpGlobalSpinnerService, private router: Router) { }

    ngOnInit(): void {
        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof RouteConfigLoadStart) {
                this.ampSpinnerService.showSpinner();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
            }

            if (event instanceof RouteConfigLoadEnd) {
                this.ampSpinnerService.hideSpinner();
            }
        });
    }

    ngOnDestroy(): void {
        this.routerSub?.unsubscribe();
    }
}
