<!--Single Day-->
<div
    *ngIf="type == 'single' && granularity == 'day'"
    style="
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    "
>
    <form #form [formGroup]="single" class="service-form" style="width: 100%">
        <mat-form-field #singleDay appearance="fill">
            <mat-label>{{ Label }}</mat-label>
            <input
                #date
                matInput
                [max]="maxDate"
                [min]="minDate"
                formControlName="date"
                [matDatepicker]="dayPicker"
                (dateChange)="changeDate(date, dayPicker)"
            />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle
                matSuffix
                [for]="dayPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
                #dayPicker
                [startAt]="maxDate"
                startView="multi-year"
            ></mat-datepicker>
        </mat-form-field>
    </form>
</div>
<!--Single Month-->
<div
    *ngIf="type == 'single' && granularity == 'month'"
    style="
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    "
>
    <form [formGroup]="single" class="service-form" style="width: 100%">
        <mat-form-field #singleMonth appearance="fill">
            <mat-label>{{ l("EnterMonth") }}</mat-label>
            <input
                matInput
                #date
                [max]="maxDate"
                [min]="minDate"
                formControlName="date"
                [matDatepicker]="monthPicker"
                placeholder="{{ placeHolderDate }}"
            />
            <mat-hint>YYYY/MM</mat-hint>
            <mat-datepicker-toggle
                matSuffix
                [for]="monthPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
                #monthPicker
                startView="multi-year"
                (monthSelected)="setMonthAndYear($event, monthPicker)"
            ></mat-datepicker>
        </mat-form-field>
    </form>
</div>
<!--Day Range-->
<div
    *ngIf="type == 'range' && granularity == 'day'"
    style="
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    "
>
    <form [formGroup]="range" class="service-form" style="width: 100%">
            <mat-form-field #dayRange appearance="fill">
                <mat-label> {{ l("EnterDateRange") }} </mat-label>
                <!-- <mat-label *ngIf="hasProductSelected && !changed"> {{ startDateString + ' - ' + endDateString }}</mat-label> -->
            <mat-date-range-input
                [rangePicker]="dayRangePicker"
                [min]="minDate"
                [max]="maxDate"
            >
                <input
                    matStartDate
                    #dateStart
                    [min]="minDate"
                    formControlName="start"
                    disabled
                    (focus)="dayRangePicker.open()"
                    (dateChange)="selectedStartDate($event, dayRangePicker)"
                />

                <input
                    matEndDate
                    #dateEnd
                    [max]="maxDate"
                    formControlName="end"
                    disabled
                    (dateChange)="
                        changeDateRange(dateStart, dateEnd, dayRangePicker)
                    "
                />
            </mat-date-range-input>
            <mat-datepicker-toggle
                matSuffix
                [for]="dayRangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker
                #dayRangePicker
                startView="{{ startingView }}"
                [startAt]="startingYear"
                (closed)="openDatePicker(dayRangePicker)"
                disabled="false"
            ></mat-date-range-picker>
        </mat-form-field>
    </form>
</div>
<!--Month Range-->
<div
    *ngIf="type == 'range' && granularity == 'month'"
    style="
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    "
>
    <form [formGroup]="range" class="service-form" style="width: 100%">
        <mat-form-field #monthRange appearance="fill">
            <mat-label class="ms-3">{{ monthRangeLabel }}</mat-label>
            <mat-date-range-input
                [rangePicker]="monthRangePicker"
                [max]="maxDate"
                [min]="minDate"
                [disabled]="true"
            >
                <input
                    matStartDate
                    [min]="minDate"
                    #dateStart
                    formControlName="start"
                    disabled
                />

                <input
                    matEndDate
                    [max]="maxDate"
                    #dateEnd
                    formControlName="end"
                    disabled
                />
            </mat-date-range-input>
            <mat-datepicker-toggle
                matSuffix
                [for]="monthRangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker
                #monthRangePicker
                startView="{{ startingView }}"
                [startAt]="startingYear"
                (closed)="openDatePicker(monthRangePicker)"
                (monthSelected)="setMonthAndYearRange($event, monthRangePicker)"
                disabled="false"
            ></mat-date-range-picker>
        </mat-form-field>
    </form>
</div>
