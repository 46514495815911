<div  [matMenuTriggerFor]="menu" [class]="customStyle" id="notificationIcon" class="position-relative">
    <i class="flaticon-alert-2 unread-notification" *ngIf="unreadNotificationCount"></i>
    <span class="label label-warning unread-notification-count" *ngIf="unreadNotificationCount">
        {{unreadNotificationCount}}
    </span>
    <i class="flaticon-alarm" *ngIf="!unreadNotificationCount"></i>
</div>

<mat-menu #menu="matMenu" class="p-0 menu-container">
        <div class="cabecera-container" [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/default/images/bg-1.jpg)', 'background-size': 'cover'}">
            <h4>
                <span class="text-white"> {{ 'Notifications' | localize }} </span>
                <span class="header-background-cant">
                    {{ unreadNotificationCount }} {{ 'New' | localize }}
                </span>
            </h4>
        </div>
        <div class="settings-container d-flex justify-content-between ">
            <div>
                <a href="javascript:;"
                    (click)="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation();"
                    id="setAllNotificationsAsReadLink"
                    *ngIf="unreadNotificationCount">{{"SetAllAsRead" | localize}}</a>
            </div>
            <span id="openNotificationSettingsModalLink" (click)="openNotificationSettingsModal()">
                {{ 'Settings' | localize }}
            </span>
        </div>
        <div>
            <div style="cursor: context-menu;">
                <a *ngFor="let notification of notifications" (click)="gotoUrl(notification.url)"
                    [ngClass]="{'user-notification-item-clickable': notification.url,'user-notification-item-unread': notification.isUnread}"
                    class="navi-item mb-2">
                    <div class="navi-link d-flex align-items-center px-5 my-3">
                        <div class="navi-icon me-5">
                            <i class="{{notification.icon}} {{notification.iconFontClass}}"></i>
                        </div>
                        <div class="navi-text">
                            <div class="font-weight-bold notification-text">
                                {{notification.text}}
                            </div>
                            <!-- <div class="text-muted">
                                {{notification.time }}
                            </div> -->
                            <span *ngIf="notification.isUnread"
                                class="btn btn-link-success set-notification-as-read"
                                (click)="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();">
                                {{"SetAsRead" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <span *ngIf="!notifications.length" class="notification-empty-text p-3">
                    {{"ThereAreNoNotifications" | localize}}
                </span>
            </div>
            <div *ngIf="notifications.length">
                <hr/>
                <div class="text-center link-all-notifications"><a routerLink="notifications" >{{"SeeAllNotifications" | localize}}</a>
                </div>
            </div>
        </div>
</mat-menu>
