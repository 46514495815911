import { Component } from '@angular/core';

@Component({
  selector: 'amp-global-spinner',
  standalone: true,
  templateUrl: './amp-global-spinner.component.html',
  styleUrl: './amp-global-spinner.component.less'
})
export class AmpGlobalSpinnerComponent {

}
