import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type SpinnerType = 'normal' | 'custom';
@Injectable({
	providedIn: 'root'
})
export class AmpGlobalSpinnerService {
	public customIsActive$: Observable<boolean>;

	public normalIsActive$: Observable<boolean>
	public normalTextSpinner$: Observable<string>;

	/**
	 * Used to show a normal mat-spinner in the middle of the screen.
	 */
	private normalSpinner = new BehaviorSubject<boolean>(false);

	/**
	 * Used to show a custom spinner in the middle of the screen.
	 * @see{@link AmpGlobalSpinnerComponent}
	 */
	private customSpinner = new BehaviorSubject<boolean>(false);
	private normalTextSpinner = new BehaviorSubject<string>('');


	constructor() {
		this.customIsActive$ = this.customSpinner.asObservable();
		this.normalIsActive$ = this.normalSpinner.asObservable();
		this.normalTextSpinner$ = this.normalTextSpinner.asObservable();
	}

	/**
	 * Show a spinner in the middle of the screen.
	 * @param type could be custom (amp custom spinner) or normal (mat-spinner)
	 * @param text text to show in the normal spinner. **TEXT MUST BE NOT LOCALIZED**
	 */
	public showSpinner(type: SpinnerType = 'custom', text?: string): void {
		switch (type) {
			case 'custom': this.customSpinner.next(true); break;
			case 'normal': {
				this.normalSpinner.next(true);
				this.normalTextSpinner.next(text);
				break;
			}
			default: this.customSpinner.next(true); break;
		}
	}

	/**
	 * Hide the spinner. If the spinner is not shown, nothing happens.
	 */
	public hideSpinner(): void {
		this.customSpinner.next(false);
		this.normalSpinner.next(false);
		this.normalTextSpinner.next('');
	}


}
